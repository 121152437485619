const actions = {
    LIST_TIMESHEET: 'LIST_TIMESHEET',
    LIST_AGENCIES: 'LIST_AGENCIES',
    LIST_COMPANIES: 'LIST_COMPANIES',
    POST_TIMESHEET: 'POST_TIMESHEET',
    GET_TIMESHEET: "GET_TIMESHEET",
    PUT_TIMESHEET: "PUT_TIMESHEET",
    UPLOAD_FILE: "UPLOAD_FILE",
    COLLABORATOR_LIST: "COLLABORATOR_LIST",
    EXPORT_DATA: "EXPORT_DATA",
    LIST_MISSIONS: 'LIST_MISSIONS',

    exportData: data => {
        return {
            type: actions.EXPORT_DATA,
            data
        }
    },

    getAllTimesheet: data => {
        return {
            type: actions.LIST_TIMESHEET,
            data
        }
    },

    getListAgenciesTimesheet: data => {
        return {
            type: actions.LIST_AGENCIES,
            data
        }
    },

    getListCompaniesTimesheet: data => {
        return {
            type: actions.LIST_COMPANIES,
            data
        }
    },

    postTimesheet: data => {
        return {
            type: actions.POST_TIMESHEET,
            data
        }
    },

    getTimesheet: data => {
        return {
            type: actions.GET_TIMESHEET,
            data
        }
    },
    
    putTimesheet: data => {
        return {
            type: actions.PUT_TIMESHEET,
            data
        }
    },

    uploadFile: data => {
        return {
            type: actions.UPLOAD_FILE,
            data
        }
    },

    getCollaboratorList: data => {
        return {
            type: actions.COLLABORATOR_LIST,
            data
        }
    },

    getListMissionsTimes: data => {
        return {
            type: actions.LIST_MISSIONS,
            data 
        }
    },
}

export default actions;