import actions from "./actions";

const { MY_DOCUMENTS_LIST } =  actions;

const initialState = {
    listDocuments: null
}

const myDocumentsReducer = (state = initialState, action) => {
    const { type, data } = action;
    switch (type) {
        case MY_DOCUMENTS_LIST:
            return { ...state, listDocuments: data }
    
        default:
            return state
    }
}

export default myDocumentsReducer