const actions = {
  LIST_NOTIFICATIONS: "LIST_NOTIFICATIONS",
  ONE_NOTIFICATION: "ONE_NOTIFICATION",

  listNotifications: (data) => {
    return {
      type: actions.LIST_NOTIFICATIONS,
      data,
    };
  },
  oneNotification: (data) => {
    return {
      type: actions.ONE_NOTIFICATION,
      data,
    };
  }
};
export default actions;
