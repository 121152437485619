const actions = {
    STATISTIQUE_CANDIDATES: 'STATISTIQUE_CANDIDATES',
    STATISTIQUE_CANDIDATURES: 'STATISTIQUE_CANDIDATURES',
    MULTIPOSTING: "MULTIPOSTING",
    BASE_CV: "BASE_CV",
    STATICS_STATS: "STATICS_STATS",

    baseCv: data => {
        return {
            type: actions.BASE_CV,
            data
        }
    },

    staticsStats: data => {
        return {
            type: actions.STATICS_STATS,
            data
        }
    },

    multiposting: data => {
        return {
            type: actions.MULTIPOSTING,
            data
        }
    },

    listCandiates: data => {
        return {
            type: actions.STATISTIQUE_CANDIDATES,
            data
        }
    },

    listCandidatures: data => {
        return {
            type: actions.STATISTIQUE_CANDIDATURES,
            data
        }
    },
}

export default actions;