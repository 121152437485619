import actions from "./actions";

const { LIST_SEARCH, DELETE_SEARCH, LIST_USER_SECTORS, GET_ONE_SAVED_SEARCH, SAVE_SEARCH, CLEAR_DATA } = actions;

const initialState = {
    listSavedSearch: [],
    deleteSearch: null,
    listSectors: [],
    oneSearch: null,
    saveSearch: false,
}

const savedSearchReducer = (state = initialState, action) => {
    const { type, data } = action;
    switch (type) {
        case LIST_SEARCH:
            return {
                ...state,
                listSavedSearch: data
            }
        case DELETE_SEARCH:
            return {
                ...state,
                deleteSearch: data
            }
        case LIST_USER_SECTORS:
            return {
                ...state,
                listSectors: data
            }
        case GET_ONE_SAVED_SEARCH:
            return {
                ...state,
                oneSearch: data
            }
        case SAVE_SEARCH:
            return {
                ...state,
                saveSearch: data
            }
        case CLEAR_DATA:
            return {
                ...state,
                listSavedSearch: []
            }
        default:
            return state
    }
}

export default savedSearchReducer;