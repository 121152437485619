import actions from './actions';

const { PARSING_CV } = actions;

const initialState = {
    parsing: null
}

const parsingReducer = (state = initialState, action) => {
    const {type, data} = action; 
    switch (type) {
        case PARSING_CV:
            return {
                ...state,
                parsing: data
            }
        default:
            return state;
    }
};

export default parsingReducer;