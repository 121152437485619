import actions from "./actions";

const { DATA_EXPORT } = actions;

const initialState = {
    dataExport: null
}

const exportReducer = (state = initialState, action) => {
    const { type, data } = action;
    switch (type) {
        case DATA_EXPORT:
            return { ...state, dataExport: data }
        default:
            return state
    }
}

export default exportReducer