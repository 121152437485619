import actions from "./actions";

const { MY_LIST_FOLDERS, LIST_CANDIDATE, CANDIDATE_DETAILS } = actions;

const initiaState = {
    myFolders: null,
    listCandidate: null,
    candidateDetail: null
}

const myListFoldersreducer = (state = initiaState, action) => {
    const { type, data } = action;
    switch (type) {
        case MY_LIST_FOLDERS:
            return { ...state, myFolders: data }
        case LIST_CANDIDATE:
            return { ...state, listCandidate: data }
        case CANDIDATE_DETAILS:
            return { ...state, candidateDetail: data }
        default:
            return state
    }
}

export default myListFoldersreducer;