const actions = {
    USER_TYPE: 'USER_TYPE',
    LOGIN_SUCCESS: 'LOGIN_SUCCESS',
    FORGOT_PASSWORD: 'FORGOT_PASSWORD',
    RESET_PASSWORD: 'RESET_PASSWORD',
    SECTORS_LIST: 'SECTORS_LIST',
    PROFESSIONS_LIST: 'PROFESSIONS_LIST',
    JOBS_TYPES_LIST: 'JOBS_TYPES_LIST',
    NATIONALITIES_LIST: 'NATIONALITIES_LIST',
    LOCATIONS_LIST: 'LOCATIONS_LIST',
    AGENCIS_LIST: 'AGENCIS_LIST',
    WORKING_PERMISSIONS_LIST: 'WORKING_PERMISSIONS_LIST',
    ACCOUNT_DISABLED: 'ACCOUNT_DISABLED',
    REACTIVATE_ACCOUNT: 'REACTIVATE_ACCOUNT',
    REGISTER_AGENCY: 'REGISTER_AGENCY',

    userTypeToggle: data => {
        return {
            type: actions.USER_TYPE,
            data,
        }
    },
    loginSuccess: data => {
        return {
            type: actions.LOGIN_SUCCESS,
            data,
        }
    },
    isAccountDisabled: data => {
        return {
            type: actions.ACCOUNT_DISABLED,
            data,
        }
    },
    forgetPasswordAction: data => {
        return {
            type: actions.FORGOT_PASSWORD,
            data,
        }
    },
    resetPasswordAction: data => {
        return {
            type: actions.RESET_PASSWORD,
            data,
        }
    },
    sectors: data => {
        return {
            type: actions.SECTORS_LIST,
            data
        }
    },
    jobsTypes: data => {
        return {
            type: actions.JOBS_TYPES_LIST,
            data
        }
    },
    professions: data => {
        return {
            type: actions.PROFESSIONS_LIST,
            data
        }
    },
    locations: data => {
        return {
            type: actions.LOCATIONS_LIST,
            data
        }
    },
    nationalities: data => {
        return {
            type: actions.NATIONALITIES_LIST,
            data
        }
    },
    workingPermission: data => {
        return {
            type: actions.WORKING_PERMISSIONS_LIST,
            data
        }
    },
    agencesList: data => {
        return {
            type: actions.AGENCIS_LIST,
            data
        }
    },
    reactivateAccount: () => {
        return {
            type: actions.REACTIVATE_ACCOUNT,
        }
    },
    registerAgency: data => {
        return {
            type: actions.REGISTER_AGENCY,
            data
        }
    }
}

export default actions;