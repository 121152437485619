import actions from "./actions";

const { LIST_PREFERENCES } = actions;
const initialState = {
  listPreferences: [],
};

const CommunicateReducer = (state = initialState, action) => {
  const { type, data } = action;
  switch (type) {
    case LIST_PREFERENCES:
      return { ...state, listPreferences: data };
    default:
      return state;
  }
};
export { CommunicateReducer };
