const actions = {
    MISSION_ADDED: "MISSION_ADDED",
    MISSION_MODIFIED: "MISSION_MODIFIED",
    LIST_CONSULTINGS: "LIST_CONSULTINGS",
    LIST_PLATEFORM_SECURSAL: "LIST_PLATEFORM_SECURSAL",
    LIST_CLIENTS: "LIST_CLIENTS",
    LIST_QUALIFICATIONS: "LIST_QUALIFICATIONS",
    ALL_CLIENT: "ALL_CLIENT",
    POST_CLIENT: "POST_CLIENT",
    LIST_IMAGES_BY_SECTOR: "LIST_IMAGES_BY_SECTOR",

    missionAdded: (data) => {
        return {
            type: actions.MISSION_ADDED,
            data
        }
    },
    missionModified: (data) => {
        return {
            type: actions.MISSION_MODIFIED,
            data
        }
    },
    postDuplicate: (data) => {
        return {
            type: actions.POST_CLIENT,
            data
        }
    },

    getAllClient: (data) => {
        return {
            type: actions.ALL_CLIENT,
            data
        }
    },

    getListConsultings: (data) => {
        return {
            type: actions.LIST_CONSULTINGS,
            data
        }
    },

    getListPlateformSecursal: (data) => {
        return {
            type: actions.LIST_PLATEFORM_SECURSAL,
            data
        }
    },

    getListClients: (data) => {
        return {
            type: actions.LIST_CLIENTS,
            data
        }
    },

    getListQualifications: (data) => {
        return {
            type: actions.LIST_QUALIFICATIONS,
            data
        }
    },

    getImagesSector: (data) => {
        return {
            type: actions.LIST_IMAGES_BY_SECTOR,
            data
        }
    }
}

export default actions;