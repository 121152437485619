import actions from './actions';

const { TOGGLE_MODAL, NEW_FOLDER, LIST_PRESENTATION_FOLDER, DETAIL_FOLDER, DETAIL_AGENCY } = actions;

const initialState = {
    isModalOpen: false,
    presentationDocs: null,
    urlNewFolder: null,
    detailFolder: null,
    detailAgency: null
}

const presentationFileReducer = (state = initialState, action) => {
    const { type, data } = action;

    switch (type) {
        case TOGGLE_MODAL:
            return { ...state, isModalOpen: !state.isModalOpen };
        case NEW_FOLDER:
            return { ...state, urlNewFolder: data }
        case LIST_PRESENTATION_FOLDER:
            return { ...state, presentationDocs: data }
        case DETAIL_FOLDER:
            return { ...state, detailFolder: data }
        case DETAIL_AGENCY:
            return { ...state, detailAgency: data }
        default:
            return state;
    }
};
export { presentationFileReducer };