const actions = {
  ALL_CANDIDATURE: "ALL_CANDIDATURE",
  ALL_PROPOSED_CANDIDATE: "ALL_PROPOSED_CANDIDATE",
  SET_CANDIDATURE_STATUS: "SET_CANDIDATURE_STATUS",

  getAllCandidature: (data) => {
    return {
      type: actions.ALL_CANDIDATURE,
      data,
    };
  },

  getAllProposedCandidate: (data) => {
    return {
      type: actions.ALL_PROPOSED_CANDIDATE,
      data,
    };
  },

  setCandidateStatus: (data) => {
    return {
      type: actions.SET_CANDIDATURE_STATUS,
      data,
    };
  },
};

export default actions;
