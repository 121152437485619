const actions = {
    GET_ALL_MISSIONS: 'GET_ALL_MISSIONS',
    FILTER_MISSIONS: 'FILTER_MISSIONS',
    GET_UNINTERESTING_IDS: 'GET_UNINTERESTING_IDS',
    GET_MISSION_BY_ID: 'GET_MISSION_BY_ID',
    ADD_VIEWS: 'ADD_VIEWS',
    CLEAR_MISSION: 'CLEAR_MISSION',

    missions: (data) => {
        return {
            type: actions.GET_ALL_MISSIONS,
            data,
        };
    },

    filterMissions: (data) => {
        return {
            type: actions.FILTER_MISSIONS,
            data,
        };
    },

    getMissionById: (data) => {
        return {
            type: actions.GET_MISSION_BY_ID,
            data
        }
    },
    
    addViews: (data) => {
        return {
            type: actions.ADD_VIEWS,
            data
        }
    },

    clearMission: () => {
        return {
            type: actions.CLEAR_MISSION,
            data: null
        }
    }

};

export default actions;
