import actions from './actions';

const { GET_ALL_MISSIONS, FILTER_MISSIONS, GET_UNINTERESTING_IDS, GET_MISSION_BY_ID, ADD_VIEWS, CLEAR_MISSION } = actions;

const initialState = {
    missions: null,
    mission: null,
    filteredMissions: null,
    addView: null,
}

const MissionsReducer = (state = initialState, action) => {
    const { type, data } = action;
    switch (type) {
        case GET_ALL_MISSIONS:
            return { ...state, missions: data };
        case FILTER_MISSIONS:
            return { ...state, filteredMissions: data };
        case GET_MISSION_BY_ID:
            return { ...state, mission: data };
        case ADD_VIEWS:
            return { ...state, addView: data };
        case CLEAR_MISSION:
            return {...state, mission: null}
        default:
            return state;
    }
};
export { MissionsReducer };