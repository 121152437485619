import actions from "./actions";
const { LIST_NOTIFICATIONS, ONE_NOTIFICATION } = actions;

const initialState = {
  notifications: null,
  notification: null
};

const NotificationsReducer = (state = initialState, action) => {
  const { type, data } = action;
  switch (type) {
    case LIST_NOTIFICATIONS:
      return { ...state, notifications: data };
    case ONE_NOTIFICATION:
      return { ...state, notification: data };
    default:
      return state;
  }
};
export { NotificationsReducer };
