import actions from "./actions";

const { LIST_MISSIONS, LIST_TIMESHEET, LIST_AGENCIES, LIST_COMPANIES, POST_TIMESHEET, GET_TIMESHEET, PUT_TIMESHEET, UPLOAD_FILE, COLLABORATOR_LIST, EXPORT_DATA } = actions;

const initialState = {
    listTimesheet: null,
    listAgencies: null,
    listCompanies: null,
    postTimesheet: null,
    getTimesheet: null,
    putTimesheet: null,
    uploadFile: null,
    collaboratorList: null,
    dataExport: null,
    listMissions: null
};

const timesheetReducer = (state = initialState, action) => {
    const { type, data } = action;
    switch (type) {
        case EXPORT_DATA:
            return { ...state, dataExport: data };
        case LIST_TIMESHEET:
            return { ...state, listTimesheet: data };
        case LIST_AGENCIES:
            return { ...state, listAgencies: data };
        case LIST_COMPANIES:
            return { ...state, listCompanies: data };
        case POST_TIMESHEET:
            return {...state, postTimesheet: data};
        case GET_TIMESHEET:
            return {...state, getTimesheet: data};
        case PUT_TIMESHEET:
            return {...state, putTimesheet: data};
        case UPLOAD_FILE:
            return {...state, uploadFile: data}
        case COLLABORATOR_LIST:
                return {...state, collaboratorList: data}
        case LIST_MISSIONS:
            return {...state, listMissions: data}
        default:
            return state;
    }
}

export default timesheetReducer;