import actions from "./actions";

const { IS_PENDING, IS_ERROR, IS_SUCCESS } = actions

const initialState = {
    isPending: false,
    isError: false,
    isSuccess: false
}

const sharedReducer = (state = initialState, action) => {
    const { type, data } = action;

    switch (type) {
        case IS_PENDING:
          return { ...state, isPending: data };
        case IS_ERROR:
          return { ...state, isError: data };
        case IS_SUCCESS:
          return { ...state, isSuccess: data };
        default:
          return state;
      }
}

export { sharedReducer };