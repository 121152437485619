const actions = {
    DATA_EXPORT: "DATA_EXPORT",

    dataExport: data => {
        return {
            type: actions.DATA_EXPORT,
            data
        }
    }
}

export default actions