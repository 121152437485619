const actions = {
    STATISTIQUE: 'STATISTIQUE',
    STATISTIQUE_ADS: 'STATISTIQUE_ADS',
    ALL_MISSIONS: 'ALL_MISSIONS',
    ALL_CONTACT: 'ALL_CONTACT',
    DETAIL_AD: 'DETAIL_AD',
    DETAIL_ANNONCE: 'DETAIL_ANNONCE',

    getAnnonce: data => {
        return {
            type: actions.DETAIL_ANNONCE,
            data
        }
    },

    statistique: data => {
        return {
            type: actions.STATISTIQUE,
            data
        }
    },

    statistiqueAds: data => {
        return {
            type: actions.STATISTIQUE_ADS,
            data
        }
    },

    allMissions: data => {
        return {
            type: actions.ALL_MISSIONS,
            data
        }
    },
    
    listContact: data => {
        return {
            type: actions.ALL_CONTACT,
            data
        }
    },

    getDetailAd: data => {
        return {
            type: actions.DETAIL_AD,
            data
        }
    }
}

export default actions;