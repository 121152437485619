const actions = {
    GET_USER_DETAILS: 'GET_USER_DETAILS',
    LIST_MULTIPOSTING_USER: 'LIST_MULTIPOSTING_USER',
    EDIT_USER_DETAILS: 'EDIT_USER_DETAILS',
    UPLOAD_PROFILE_IMAGE: 'UPLOAD_PROFILE_IMAGE',
    ADD_FORMATION: 'ADD_FORMATION',
    DELETE_FORMATION: 'DELETE_FORMATION',
    ADD_EXP: 'ADD_EXP',
    DELETE_EXP: 'DELETE_EXP',
    DELETE_FILE: 'DELETE_FILE',
    DELETE_FILES: 'DELETE_FILES',
    EDIT_FORMATION: 'EDIT_FORMATION',
    EDIT_EXP: 'EDIT_EXP',
    GET_LANG_LEVELS: 'GET_LANG_LEVELS',
    GET_EDU_LEVEL: 'GET_EDU_LEVEL',
    GET_PRE_DEF_LANG: 'GET_PRE_DEF_LANG',
    GET_FILTERED_LANGS: 'GET_FILTERED_LANGS',
    GET_JOB_TYPES: 'GET_JOB_TYPES',
    GET_EDUC_LEVEL: 'GET_EDUC_LEVEL',
    CHECK_CANDIDATE_HAD_CANDIDATURES: 'CHECK_CANDIDATE_HAD_CANDIDATURES',

    userDetails: data => {
        return {
            type: actions.GET_USER_DETAILS,
            data,
        }
    },

    userMultiposting: data => {
        return {
            type: actions.LIST_MULTIPOSTING_USER,
            data
        }
    },

    editUserDetails: data => {
        return {
            type: actions.EDIT_USER_DETAILS,
            data,
        }
    },

    uploadProfileImage: data => {
        return {
            type: actions.UPLOAD_PROFILE_IMAGE,
            data,
        }
    },

    addFormation: data => {
        return {
            type: actions.ADD_FORMATION,
            data,
        }
    },

    deleteFormation: data => {
        return {
            type: actions.DELETE_FORMATION,
            data,
        }
    },

    addExp: data => {
        return {
            type: actions.ADD_EXP,
            data,
        }
    },

    deleteExp: data => {
        return {
            type: actions.DELETE_EXP,
            data,
        }
    },

    deleteFile: data => {
        return {
            type: actions.DELETE_FILE,
            data,
        }
    },

    deleteFiles: data => {
        return {
            type: actions.DELETE_FILES,
            data,
        }
    },

    editFormation: data => {
        return {
            type: actions.EDIT_FORMATION,
            data,
        }
    },

    editExp: data => {
        return {
            type: actions.EDIT_EXP,
            data,
        }
    },

    getLangLevels: data => {
        return {
            type: actions.GET_LANG_LEVELS,
            data,
        }
    },

    getEduLevels: data => {
        return {
            type: actions.GET_EDU_LEVEL,
            data,
        }
    },

    getEduLevel: data => {
        return {
            type: actions.GET_EDUC_LEVEL,
            data,
        }
    },

    getPredefLang: data => {
        return {
            type: actions.GET_PRE_DEF_LANG,
            data,
        }
    },

    getFilteredfLangs: data => {
        return {
            type: actions.GET_FILTERED_LANGS,
            data,
        }
    },

    getJobTypes: data => {
        return {
            type: actions.GET_JOB_TYPES,
            data,
        }
    },

    checkCandidateHadCandidatres: data => {
        return {
            type: actions.CHECK_CANDIDATE_HAD_CANDIDATURES,
            data,
        }
    }
}


export default actions;