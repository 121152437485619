import actions from "./actions";

const { STATISTIQUE_CANDIDATES, STATISTIQUE_CANDIDATURES, MULTIPOSTING, BASE_CV, STATICS_STATS } = actions;

const initialState = {
    candidates: null,
    candidatures: null,
    multiposting: null,
    base: null,
    statics: null
}

const dashbordAgencyReducer = (state = initialState, action) => {
    const { type, data } = action;
    switch (type) {
        case BASE_CV:
            return {
                ...state,
                base: data
            }
        case STATICS_STATS:
            return {
                ...state,
                statics: data
            }
        case MULTIPOSTING:
            return {
                ...state,
                multiposting: data
            }
        case STATISTIQUE_CANDIDATES:
            return {
                ...state,
                candidates: data
            }
        case STATISTIQUE_CANDIDATURES:
            return {
                ...state,
                candidatures: data
            }
        default:
            return state
    }
}

export default dashbordAgencyReducer;