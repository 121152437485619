const actions = {
  PERCENTAGE: "PERCENTAGE",
  STATISTIC_DASHBORD: "STATISTIC_DASHBORD",
  DELETE_ELEMENT_CADIDATURE_REFUSED: "DELETE_ELEMENT_CADIDATURE_REFUSED",
  DELETE_ELEMENT_CADIDATURE_CANCELED: "DELETE_ELEMENT_CADIDATURE_CANCELED",

  profilePercent: (data) => {
    return {
      type: actions.PERCENTAGE,
      data,
    };
  },
  dashbordStatistic: (data) => {
    return {
      type: actions.STATISTIC_DASHBORD,
      data,
    };
  },
  deleteElementRefused: (data) => {
    return {
      type: actions.DELETE_ELEMENT_CADIDATURE_REFUSED,
      data,
    };
  },
  deleteElementCanceled: (data) => {
    return {
      type: actions.DELETE_ELEMENT_CADIDATURE_CANCELED,
      data,
    };
  },
};

export default actions;
